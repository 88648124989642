import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import RestApi from '../../Apis/RestApi';
import QRCodeStyling from 'qr-code-styling';
import { nanoid } from 'nanoid';

import roundedStyle from '../../Assets/DotsStyles/rounded.png';
import dotsStyle from '../../Assets/DotsStyles/dots.png';
import classyStyle from '../../Assets/DotsStyles/classy.png';
import classyRoundedStyle from '../../Assets/DotsStyles/classy-rounded.png';
import squareStyle from '../../Assets/DotsStyles/square.png';
import extraRoundedStyle from '../../Assets/DotsStyles/extra-rounded.png';

// Create a mapping of styles to their corresponding images
const styleImages = {
  rounded: roundedStyle,
  dots: dotsStyle,
  classy: classyStyle,
  'classy-rounded': classyRoundedStyle,
  square: squareStyle,
  'extra-rounded': extraRoundedStyle
};

function QrCodePopUp({ setCreateQR, selectedQR, edit }) {
  const [selectStyle, setSelectStyle] = useState("square");
  const [backgroundColor, setBackgroundColor] = useState("#ffffff");
  const [squareColor, setSquareColor] = useState("#000000");
  const [pixelColor, setPixelColor] = useState("#000000");
  const [logo, setLogo] = useState(null);
  const [link, setLink] = useState(edit?selectedQR?.qr_url : "");
  const [userType, setUserType] = useState(null);
  const navigate = useNavigate();
  const qrCodeRef = useRef(null);
  const [qrCodeName, setQrCodeName]=useState(edit?selectedQR.qr_name?selectedQR.qr_name:selectedQR.qr_url : "")
  const [qrBase64, setQrBase64] = useState();
  const accountType = localStorage.getItem("type");
  const userId = localStorage.getItem('_id');
  const short_id = nanoid(8);

  

  useEffect(() => {
    const storedType = localStorage.getItem('type');
    setUserType(storedType);
  }, []);

  const generateQRCode = useCallback(() => {
    if (!qrCodeRef.current) return;

    const Rlink = `https://s-qc.in/redirect/${short_id}`;

    const qrCode = new QRCodeStyling({
      width: 768,
      height: 768,
      data: Rlink,
      margin: 50,
      dotsOptions: {
        color: pixelColor,
        type: selectStyle,
      },
      backgroundOptions: {
        color: backgroundColor,
      },
      cornersSquareOptions: {
        color: pixelColor,
      },
      image: logo,
    });

    qrCodeRef.current.innerHTML = '';
    qrCode.append(qrCodeRef.current);
  }, [link, selectStyle, backgroundColor, squareColor, pixelColor, logo]);

  useEffect(() => {
    generateQRCode();
  }, [generateQRCode]);

  useEffect(() => {
    const canvas = qrCodeRef.current?.querySelector('canvas');
    if (canvas) {
      // Set your desired height and width here
      canvas.style.height = '220px'; // Change to desired height
      canvas.style.width = '220px'; // Change to desired width
    }
  }, [qrCodeRef, generateQRCode]);

  const handleStyleSelection = (option) => {
    setSelectStyle(option);
  };
  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setLogo(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSave = () => {
    if(link===''){
      toast.error("Link can't be empty.")
      return;
    }
    const options = {
      link,
      selectStyle,
      backgroundColor,
      squareColor,
      pixelColor,
      logo,
    };
    handleGenerateQR(options);
  };

  const handleGenerateQR = async (options) => {
    if (edit) {
      const data = {
        qr_short_id: selectedQR.qr_short_id,
        qr_url: options.link || selectedQR.qr_url,
        qr_name:qrCodeName
      };

      const response = await RestApi.updateQr(data);
      if(response.data.status){
        toast.success("Qr Updated Successfully")
        setCreateQR(false);
      }else{
        toast.error("Error Updating Qr, Try Again Later!")
      }
    } else {
      // const id = nanoid(8);

      const canvas = qrCodeRef.current.querySelector('canvas');
      const base64data = canvas.toDataURL('image/png');
      setQrBase64(base64data);

      const data = {
        user_id: userId,
        qr_short_id: short_id,
        qr_url: options.link,
        qr_type: 'dynamic',
        qr_image: base64data,
        qr_name:qrCodeName
      };

      const response = await RestApi.createQR(data);
      if(response.data.status){
        toast.success("Qr Created Successfully");
        setCreateQR(false);
      }
      else{
        toast.error('Error Generating Qr, Try Again Later')
      }
    }
  };

  const linkHandler=(e)=>{
    e.preventDefault()
    setLink(e.target.value)
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10 p-4 overflow-y-hidden-hidden">
      <div className="relative overflow-y-hidden-hidden">
        <button
          className="absolute -top-4 -right-4 text-white bg-gray-700 hover:bg-gray-600 p-2 z-[999] rounded-full"
          onClick={() => setCreateQR(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <div className={`bg-[#223153] rounded-lg p-6 w-full max-w-5xl lg:max-h-[95vh] md:max-h-[80vh] max-h-[75vh] overflow-y-auto ${edit ? "max-w-lg min-w-[50vw]" : ""} relative`}>
          <div className="flex flex-col lg:flex-row">
            <div className={`${edit ? "w-full " : "w-full lg:w-[50%] lg:mr-4"}`}>
              <h2 className="text-lg font-bold text-white mb-4">
                {edit ? "Edit your QR Code" : "Add New QR Code Link"}
              </h2>
              <input
                type="text"
                placeholder="Name"
                value={qrCodeName}
                onChange={(e)=>setQrCodeName(e.target.value)}
                className="w-full px-3 py-2 border text-white bg-slate-600 border-gray-800 rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />              
              <input
                type="text"
                placeholder="Enter the link"
                value={link}
                onChange={linkHandler}
                className="w-full px-3 py-2 border text-white bg-slate-600 border-gray-800 rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              {!edit ?<div className="mt-6 flex justify-center items-center min-h-72 bg-gray-400 rounded-lg">
                <div ref={qrCodeRef} ></div>
              </div>:
              <div className='flex justify-center'>
                <img src={selectedQR?.qr_image} alt="Smart Qr Codes" className='h-1/2 w-1/2'></img>
              </div>
              }
              {(edit || userType === "FREE") && (
                <div className="flex justify-end mt-4">
                  <button
                    onClick={() => setCreateQR(false)}
                    className="bg-slate-500 text-black p-2 hover:bg-slate-700 rounded-lg px-[10px] m-2"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleSave}
                    className="bg-blue-800 text-white p-2 hover:bg-blue-600 rounded-lg px-[10px] m-2"
                  >
                    {userType === "FREE" ? "Generate QR" : "Save"}
                  </button>
                </div>
              )}
            </div>

            {!edit && (
              <div className={`w-full lg:w-[58%] bg-[#17243b] rounded-lg p-2 relative mt-4 lg:mt-0`}>
                <h2 className="text-white text-lg font-bold p-2">Customize Option</h2>

                <div className="rounded-lg p-2">
                  <h3 className="text-white underline underline-offset-3 mb-2">Style and Margin</h3>
                  <div className="flex flex-wrap justify-center">
              {Object.keys(styleImages).map((style) => (
                <div
                  key={style}
                  className={`m-1 cursor-pointer rounded-lg ${selectStyle === style ? "border-2 border-green-600" : ""}`}
                  onClick={() => handleStyleSelection(style)}
                >
                  <img 
                    src={styleImages[style]} 
                    className="rounded-lg w-24 h-24 object-cover" 
                    alt={`${style} style`} 
                  />
                  <p className={`text-center text-white text-sm ${selectStyle === style ? "bg-green-600" : ""}`}>
                    {style.replace('-', ' ')}
                  </p>
                </div>
              ))}
            </div>
                </div>

                <div className="rounded-lg p-2">
                  <h3 className="text-white underline underline-offset-4 mb-2">Color</h3>
                  <div className="flex flex-wrap justify-evenly p-1">
                    {[
                      { label: 'Background', value: backgroundColor, setter: setBackgroundColor },
                      { label: 'Pixels', value: pixelColor, setter: setPixelColor },
                    ].map(({ label, value, setter }) => (
                      <div key={label} className="w-full sm:w-2/6 mb-2">
                        <label className="text-white block">{label}</label>
                        <input
                          type="color"
                          value={value}
                          onChange={(e) => setter(e.target.value)}
                          className="w-full h-8 border-none cursor-pointer"
                        />
                      </div>
                    ))}
                  </div>
                </div>

                <div className="rounded-lg p-2">
                  <h3 className="text-white underline underline-offset-4 mb-2">Logo</h3>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleLogoUpload}
                    className="text-white p-1 w-full"
                  />
                  {logo && (
                    <div className="p-1 mt-2">
                      <img src={logo} alt="Logo preview" className="h-16 w-16 object-contain" />
                      <button
                        onClick={() => setLogo(null)}
                        className="bg-[#9d3434] text-white py-1 px-4 rounded-lg hover:bg-red-700 mt-2"
                      >
                        Remove Logo
                      </button>
                    </div>
                  )}
                </div>

                <div className="flex justify-end mt-4">
                  <button
                    onClick={() => setCreateQR(false)}
                    className="bg-slate-500 text-black p-2 hover:bg-slate-700 rounded-lg px-[10px] m-2"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleSave}
                    className="bg-blue-800 text-white p-2 hover:bg-blue-600 rounded-lg px-[10px] m-2"
                  >
                    Save
                  </button>
                </div>

                {(userType === 'FREE' && !edit) && (
                  <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-75 z-20 rounded-lg">
                    <div className="text-white text-center">
                      <h3 className="text-2xl mb-4">Unlock Premium Features!</h3>
                      <button
                        className="bg-yellow-500 text-black py-2 px-4 rounded-lg hover:bg-yellow-600"
                        onClick={() => navigate('/premium')}
                      >
                        Buy Premium
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default QrCodePopUp;